@layer components {

    /* Menu Hamburger Button */
    button.menu {
        & span {
            display: block;
            width: 60%;
            height: 4px;
            border-radius: 2px;
            position: relative;
            @apply bg-white;
            
            &::before,
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 4px;
                @apply bg-white;
                left: 0;
                top: 0;
                border-radius: 2px;
                transition: 250ms ease;
            }

            &::before {
                transform: translateY(-8px)
            }
            
            &::after {
                transform: translateY(8px)
            }
        }
    }

    .nav-open {
        & nav.main-nav {
            display: block;
        }
        & button.menu {
            & span {
                background-color: transparent;

                &::before {
                    opacity: 1;
                    transform: translateY(0px) rotate(45deg);
                }
                &::after {
                    opacity: 1;
                    transform: translateY(0px) rotate(-45deg);
                    bottom: 0;
                }
            }
        }
    }

    .no-js {
        & .form-footer {
            display: none;
        }
        & .form-step:last-child .form-footer {
            display: flex;

            & .form-previous {
                display: none;
            }
        }
    }

    a.button, button.button {
        @apply bg-secondary text-black px-6 py-2 rounded font-bold no-underline inline-block disabled:bg-gray-200 disabled:text-gray-600;
        
        /* purgecss start ignore */
        &:hover {
            @apply transition shadow-md bg-yellow-400 disabled:bg-gray-300 disabled:text-gray-600
        }

        &:active {
            @apply ring ring-offset-2 ring-yellow-400
        }
        
        &:focus {
            @apply ring ring-offset-2 ring-yellow-300 outline-none shadow-md
        }
        /* purgecss end ignore */

        &.secondary {
            @apply bg-secondary text-white
        }

        &.full {
            @apply block w-full
        }

        &.large {
            @apply py-4
        }
    }


    details {
        & summary {
            position: relative;
            padding-left: 3rem;
            list-style: none;

            /* purgecss ignore */
            &::-webkit-details-marker {
                display: none;
            }
    
            &::before {
                content: "+";
                width: 3rem;
                margin: -1rem 1rem -1rem 0;
                padding: 1rem 0;
                position: absolute;
                left: 0;
                text-align: center;
                font-size: 1.5rem;
                box-sizing: border-box;
                height: 100%;
            }
        }
    
        &[open] {
            & summary {    
                &::before {
                    content: "–";
                }
            }
        }
    }

    #alert-banner {        
        & .banner {
            @apply transform transition
        }
        &.offscreen {
            @apply invisible h-0 overflow-hidden;

            & .banner {
                @apply invisible translate-y-full
            }
        }
    }

    input#form_e0dcbe90-24fc-4f6a-ae94-663984dad622 {
        width: 100%;
      }
      
      
      input#form_ce2a4d2f-1c59-42ef-af40-47a664574a0b {
        width: 100%;
      }
      
      div#form_question_a71e5cff-6cf9-4478-8541-31794dff2f1b {
        display: none;
      }
      
      select#form_a07088b9-85dc-4f8c-8dd5-5de4c761111e {
        width: 100%;
      }
      
      div#form_question_b82d328a-6bc3-489a-aaed-379e357f14a4,
      div#form_question_89805b1e-8f95-4540-b816-b7c048de7dcd,
      div#form_question_3d9b9a03-d34a-4693-b629-1730b4e794de,
      div#form_question_4da68859-1478-4d1b-8c0b-d5be5e6f6a91 {
          margin-top: -14px;
          font-size: 14px;
      }
      div#form_question_8ecdb756-aa70-4c2c-9dab-c31ff52734c1 p span,
      div#form_question_22dea549-6480-45fe-b823-555e7d52f0b1 p span,
      div#form_question_bbf7a01a-efda-4df3-b263-0b887b682f5b p span,
      div#form_question_bcdde6a7-647b-44af-9d06-dd0bdaa7deae p span {
        font-size: 10px!important;
        display: block;
      }
      
      select#form_cd831045-3a17-4da7-a2c2-4f54099453a6 {
        width: 100%;
      }
      
      label.form_label {
        font-weight: bold;
      }

      button.default.form_button_submit {
        background: #f1c400;
        padding: 10px 60px;
        font-weight: bold;
        font-size: 20px;
        border-radius: 10px;
      }

      input[type="email"],
      input[type="text"],
      input[type="tel"] {
        appearance: none !important;
        background-color: #fff !important;
        border-color: #6b7280 !important;
        border-width: 1px !important;
        border-radius: 0 !important;
        padding: .5rem .75rem !important;
        font-size: 1rem !important;
        line-height: 1.5rem !important;
      }
      
    .embed {
        padding-top: 56.25%;
        position: relative;
        background-position: center;
        background-size: cover;
        margin-bottom: 1.5rem;
    
        & a.play {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-indent: -9999px;
            /* background: url('../images/icon-youtube.svg') center no-repeat; */
            background-position: center;
            background-repeat: no-repeat;
            background-size: 15%;
            opacity: 0.75;
            transition: opacity 150ms ease;
    
            &:hover {
                opacity: 1;
            }
        }
    
        & iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
    
            & + a.play {
                display: none;
            }
        }
    }

    h1, h2, h3 {
        @apply font-serif;
    }

    .footerlogo {
        margin: auto!important;
    }

    .ulnomargin {
        margin: 0!important;
    }

    .piechart {
        & circle.pie1 {
            transform: scale(0.95);
            transform-origin: center;
            fill: #eae7e1;
        }
        & circle.pie2 {
            stroke-width: 31.830988px;
            stroke-dashoffset: 25;
            stroke-dasharray: 0,100;
            transform-origin: center;
            fill: transparent;
            transition: 1s ease;
        }
        & circle.pie3 {
            fill: #fff;
        }
    }
}